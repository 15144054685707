* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  overflow-x: hidden;
}

@font-face {
  font-family: "bricolage";
  src: local("bricolage"), url("./fonts/bricolage.ttf") format("truetype");
}

@font-face {
  font-family: "mulish";
  src: local("mulish"), url("./fonts/mulish.ttf") format("truetype");
}

:root {
  /* font families */
  --bricolage: bricolage, sans-serif;
  --mulish: mulish, sans-serif;
  /* colors */
  --light-green: #49977f;
  --white: #ffffff;
  --dark-green: #1e3230;
  --dark-gray: #454545;
  --orange: #e37c3a;
  --light-orange: #ecae41;
  --light-grey:"#656565";

  /* font sizes */
  --h1: 96px;
  --h2: 67px;
  --h3: 50px;
  --h4: 28px;
  --h5: 24px;
  --lg: 16px;
  --md: 16px;
  --sm: 12px;
}
@media only screen and (max-width: 1339px) {
  :root {
    --h1: 60px;
    --h2: 50px;
    --h4: 24px;
  }
}

/* media queries */
@media only screen and (max-width: 1024px) {
  :root {
    --h1: 50px;
    --h2: 40px;
    --h3: 35px;
    --h4: 21px;
    --h5: 22px;
    --lg: 16px;
    --md: 16px;
    --sm: 12px;
  }
}

@media only screen and (max-width: 719px) {
  :root {
    --h1: 40px;
    --h2: 30px;
    --h3: 30px;
    --h4: 18px;
    --h5: 20px;
    --lg: 14px;
    --md: 12px;
    --sm: 12px;
  }
}

.input-green {
  background-color: #49977f;
  color: white;
  border: none;
  border-bottom: 1px solid white;
  outline: none;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
}

.input-green::placeholder {
  color: white;
}

.input-green:-webkit-autofill {
  background-color: #49977f !important;
  -webkit-box-shadow: 0 0 0px 1000px #49977f inset !important;
  -webkit-text-fill-color: white !important;
  caret-color: white !important;
}

.input-green:-moz-autofill {
  background-color: #49977f !important;
  box-shadow: 0 0 0px 1000px #49977f inset !important;
  color: white !important;
  caret-color: white !important;
}

.input-white {
  background-color: white;
  color: black;
  border: 1px solid #ccc;
  outline: none;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
}

.input-white::placeholder {
  color: #999;
}

.input-white:-webkit-autofill {
  background-color: white !important;
  -webkit-box-shadow: 0 0 0px 1000px white inset !important;
  -webkit-text-fill-color: black !important;
  caret-color: black !important;
}

.input-white:-moz-autofill {
  background-color: white !important;
  box-shadow: 0 0 0px 1000px white inset !important;
  color: black !important;
  caret-color: black !important;
}
.custom-list {
  counter-reset: list-counter;
}

.custom-list li {
  counter-increment: list-counter;

}

.custom-list li::before {
  content: counter(list-counter, lower-alpha) ") ";
  font-weight: bold;
  color:#000;
  
}